import { useContext } from "react";
import Head from "next/head";

import Layout from "../components/Layout";
import { Context } from "../context";
import { pageStyle, largeFont } from "../utils/styles";
import { getHome } from "../utils/api";
import SubNav from "../components/SubNav";
import ItemsHorizontalOverlay from "../components/ItemsHorizontalOverlay";
import ItemsGridSide from "../components/ItemsGridSide";
import ItemsGrid from "../components/ItemsGrid";
import ItemsSlider from "../components/ItemsSlider";
import GoogleAd from "../components/GoogleAd";

const Home = ({ home }) => {
  const { theme } = useContext(Context);

  return (
    <Layout>
      <Head>
        <title>Apps, Games, News, and More - AppCheeta</title>

        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.pg = window.pg || {};
              pg.disabledUnits = pg.disabledUnits || [];
              pg.disabledUnits.push('/23081961,22672082578/appcheeta_desk_sidebar1', '/23081961,22672082578/appcheeta_desk_sidebar2');
            `,
          }}
        />
      </Head>
      <div className="page-content">
        <style jsx>{pageStyle}</style>
        <style jsx>{`
          .subtitle {
            font-size: ${largeFont};
            font-weight: bold;
            margin-bottom: 2rem;
          }
          .top-reviews {
            background: ${theme.selectedBackgroundColor};
            padding: 2rem 0;
          }
          .section-wrapper {
            margin-top: 2rem;
          }
        `}</style>
        <SubNav
          links={home.reviews.slice(5).map((review) => ({
            name: review.game.name,
            url: `/review/${review.slug}`,
          }))}
        />
        {home.articles.length > 0 && (
          <div className="section">
            <ItemsHorizontalOverlay items={home.articles.slice(0, 3)} />
          </div>
        )}
        <GoogleAd section="leaderboard" />
        {home.popular_apps.length > 0 && (
          <div className="popular-apps section-wrapper">
            <div className="section">
              <h2 className="subtitle">Popular apps</h2>
              <ItemsGrid items={home.popular_apps.slice(0, 12)} path="app" />
            </div>
          </div>
        )}
        {home.app_categories.length > 0 && (
          <div className="game-categories section-wrapper">
            <div className="section">
              <h2 className="subtitle">App categories</h2>
              <ItemsSlider items={home.app_categories.slice(0, 12)} path="app" />
            </div>
          </div>
        )}
        {home.popular_games.length > 0 && (
          <div className="popular-apps section-wrapper">
            <div className="section">
              <h2 className="subtitle">Popular games</h2>
              <ItemsGrid items={home.popular_games.slice(0, 12)} path="game" />
            </div>
          </div>
        )}
        {home.categories.length > 0 && (
          <div className="game-categories section-wrapper">
            <div className="section">
              <h2 className="subtitle">Game categories</h2>
              <ItemsSlider items={home.categories.slice(0, 12)} path="game" />
            </div>
          </div>
        )}
        <GoogleAd section="leaderboard_lazy" />
        {home.new_apps.length > 0 && (
          <div className="new-apps section-wrapper">
            <div className="section">
              <h2 className="subtitle">New apps</h2>
              <ItemsGrid items={home.new_apps.slice(0, 12)} path="app" />
            </div>
          </div>
        )}
        {home.new_games.length > 0 && (
          <div className="new-games section-wrapper">
            <div className="section">
              <h2 className="subtitle">New games</h2>
              <ItemsGrid items={home.new_games.slice(0, 12)} path="game" />
            </div>
          </div>
        )}
        {home.articles.length > 3 && (
          <div className="latest-news section-wrapper">
            <div className="section">
              <h2 className="subtitle">Latest News</h2>
              <ItemsGridSide items={home.articles.slice(3, 9)} />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

Home.getInitialProps = async ({ store, cached }) => {
  const { home } = await getHome({ cached });
  const analytics = {
    content_group1: "Home",
  };
  return { home, analytics };
};

export default Home;
