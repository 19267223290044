import { cloudflareImage } from "../utils/utils";

const Image = ({ path, width, height, fit = "cover", scale = 1.5, type='' }) => {
  const fallbackImg = (type) => {
    switch (type) {
      case 'app':
        return '/images/app-placeholder.jpg'
        break;

      case 'game':
        return '/images/app-placeholder.jpg'
        break;

      case 'category':
        return '/images/category-placeholder.png'
        break;

      default:
        return '/images/placeholder.png'
        break;
    }
  };

  return (
    <div>
      <style jsx>
        {`
          div {
            padding-top: ${(height / width) * 100}%;
            position: relative;
            overflow: hidden;
            border-radius: 8px;
          }
          img {
            position: absolute;
            top: 0;
            left: 50%;
            width: 100%;
            transform: translateX(-50%);
            right: 0;
            bottom: 0;
            transition: transform 0.25s ease-in-out;
          }
          img:hover {
            transform: translateX(-50%) scale(${scale});
          }
        `}
      </style>
      <img
        data-src={cloudflareImage(path, {
          width: 2 * width * scale,
          height: 2 * height * scale,
          fit,
        })}
        src="/images/placeholder.png"
        alt=""
        onError={(e)=>{
          e.target.onerror = null;
          e.target.src=fallbackImg(type)
        }}
      />
    </div>
  );
};

export default Image;
