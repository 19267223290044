import { useContext } from "react";

import { Context } from "../context";
import { useIsMobile } from "../utils/hooks";
import { breakpoint0 } from "../utils/styles";

function GoogleAd({ section = "midview", isEnabledOnMobile = true }) {
  const { theme } = useContext(Context);

  const isMobile = useIsMobile();

  if (isMobile) {
    if (["sidebar1", "sidebar2"].includes(section)) {
      return <></>;
    }
  }

  if (isMobile && !isEnabledOnMobile) {
    return <></>;
  }

  return (
    <div className="ad">
      <style jsx>
        {`
          .adsbygoogle {
            display: inline-block;
            width: 100%;
            height: 90px;
          }
          .ad {
            margin: 0 auto 16px auto;
            text-align: center;
          }
          .sponsored {
            display: block;
            margin: 8px auto;
            color: ${theme.tertiaryTextColor};
            text-transform: uppercase;
            font-size: 12px;
            line-height: 12px;
          }
          @media screen and (min-width: ${breakpoint0}px) {
            .sponsored {
              font-size: 16px;
              line-height: 16px;
              margin: 12px auto;
            }
          }
        `}
      </style>
      <div className="sponsored">Sponsored Links</div>
      <div id={`appcheeta_${isMobile ? "mob" : "desk"}_${section}`}>
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-2933500308486888"
          data-ad-slot="9423829147"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (adsbygoogle = window.adsbygoogle || []).push({});
            `,
          }}
        />
      </div>
    </div>
  );
}

export default GoogleAd;
