import { tinyFont, smallFont, mediumFont, breakpoint1 } from "../utils/styles";
import { cloudflareImage } from "../utils/utils";

const OverlayImage = ({
  overlay,
  path,
  width,
  height,
  fit = "cover",
  scale = 1.3,
  transition = 0.3,
  position = "center",
  showOverlay = true,
}) => {
  return (
    <div>
      <style jsx>{`
        div {
          position: relative;
          padding-top: ${(height / width) * 100}%;
          overflow: hidden;
          border-radius: 8px;
        }
        img {
          position: absolute;
          top: 0;
          left: 50%;
          width: 100%;
          transform: translateX(-50%);
          transition: transform ${transition}s ease-in-out;
        }
        div:hover img {
          transform: translateX(-50%) scale(${scale});
        }
        h3 {
          position: absolute;
          top: ${position === "bottom" ? 'auto' : '50%'};
          bottom: ${position === "bottom" ? 0 : 'auto'};
          left: ${position === "bottom" ? 0 : '50%'};
          right: ${position === "bottom" ? 0 : 'auto'};
          font-size: ${position === "bottom" ? smallFont : tinyFont};
          text-align: center;
          padding: 1rem;
          line-height: 150%;
          background: rgba(0, 0, 0, 0.5);
          color: #ddd;
          transform: ${position === "bottom" ? 'none' : 'translate(-50%, -50%)'};
        }
        div:hover h3 {
          color: #fff;
          text-decoration: underline;
          text-underline-position: under;
        }
        @media screen and (min-width: ${breakpoint1}px) {
          h3 {
            font-size: ${position === "bottom" ? mediumFont : smallFont};
          }
        }
      `}</style>
      <img
        data-src={cloudflareImage(path, {
          width: parseInt(2 * width * scale),
          height: parseInt(2 * height * scale),
          fit,
        })}
        src="/images/placeholder.png"
        alt={overlay}
      />
      {showOverlay && <h3>{overlay}</h3>}
    </div>
  );
};

export default OverlayImage;
