import { useContext } from "react";
import { Context } from "../context";
import { breakpoint1 } from "../utils/styles";
import { humanizeDate } from "../utils/datetimes";
import { CommentIcon } from "../utils/icons";
import Image from "./Image";

const ItemsGridSide = ({ items }) => {
  const { theme } = useContext(Context);
  return (
    <div className="items">
      <style jsx>
        {`
          .items {
            display: grid;
            grid-template-columns: auto;
            grid-gap: 2rem;
          }
          .item {
            color: ${theme.primaryTextColor};
            text-decoration: none;
            display: grid;
            grid-template-columns: 1fr 1fr;
          }
          .title {
            font-size: 1.3rem;
            margin: 1rem 0;
          }
          .meta {
            width: 100%;
            display: flex;
            align-items: center;
          }
          .meta span {
            margin-right: 0.5rem;
          }
          .meta {
            margin-top: 0.5rem;
            color: ${theme.accentTextColor};
            font-weight: bold;
          }
          .meta :global(.comment-icon) {
            fill: ${theme.accentTextColor};
          }
          .text {
            margin-top: 0.5rem;
            margin-left: 1rem;
          }

          .item:hover .title {
            text-decoration: underline;
            text-underline-position: under;
          }

          @media screen and (min-width: ${breakpoint1}px) {
            .items {
              grid-template-columns: auto auto;
            }
          }
        `}
      </style>
      {items.map((item) => (
        <a className="item" key={item.slug} href={`/${item.type}/${item.slug}`}>
          <Image path={item.image} width={300} height={200} />
          <div className="text">
            <h3 className="title">{item.title}</h3>
            <div className="meta">
              <span className="time">{humanizeDate(new Date(item.modified))}</span>
              <span className="separator">-</span>
              <span className="icon-container">
                <CommentIcon />
              </span>
              <span className="comment-count">{item.comments}</span>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};

export default ItemsGridSide;
