import { useContext } from "react";
import Slider from "react-slick";
import { Context } from "../context";
import Image from "./Image";

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};

const ItemsSlider = ({ items, path }) => {
  const { theme } = useContext(Context);
  return (
    <div>
      <style jsx>
        {`
          .item {
            padding: 0 4px;
            text-decoration: none;
          }
          .title {
            text-align: center;
            color: ${theme.primaryTextColor};
            font-size: 1rem;
            margin-top: 0.5rem;
          }
        `}
      </style>
      <Slider {...sliderSettings}>
        {items.map((item) => (
          <a className="item" key={item.slug} href={`/${path}/${item.type}/${item.slug}`}>
            <Image path={item.image} width={350} height={200} type="category" />
            <h3 className="title">{item.name}</h3>
          </a>
        ))}
      </Slider>
    </div>
  );
};

export default ItemsSlider;
