import { useContext } from "react";
import { Context } from "../context";
import { breakpoint0, breakpoint1 } from "../utils/styles";
import { StarIcon } from "../utils/icons";
import Image from "./Image";

const ItemsGrid = ({ items, path, categorized = true, width = 100, small = false }) => {
  const { theme } = useContext(Context);

  return (
    <div className="items">
      <style jsx>
        {`
          .items {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 1.5rem;
          }
          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .item-info {
            display: inline-flex;
            align-items: center;
            margin-right: 12px;
          }
          .item-image {
            width: ${width}px;
            height: ${width}px;
            margin-right: 12px;
            border-radius: 8px;
            box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
            overflow: hidden;
          }
          .item-content {
            max-width: calc(100% - 12px - ${width}px);
          }
          .item-title {
            color: ${theme.primaryTextColor};
            font-size: 1rem;
            margin-bottom: 0.25rem;
          }
          .item-category {
            color: ${theme.tertiaryTextColor};
            font-size: 1rem;
            margin-bottom: 0.25rem;
          }
          .item-rate {
            display: inline-flex;
            align-items: center;
            color: ${theme.tertiaryTextColor};
            font-size: 0.85rem;
            height: 18px;
          }
          .item-action-btn {
            background-color: #f5efff;
            color: ${theme.primaryButtonBackgroundColor};
            font-size: 0.75rem;
            padding: 4px 12px;
            border-radius: 18px;
            text-decoration: none;
          }
          .item-action-btn:hover {
            opacity: 0.8;
          }
          @media screen and (min-width: ${breakpoint0}px) {
            .items {
              grid-template-columns: 1fr 1fr;
            }
          }
          @media screen and (min-width: ${breakpoint1}px) {
            .items {
              grid-template-columns: ${small ? '1fr 1fr' : '1fr 1fr 1fr'};
            }
          }
        `}
      </style>
      {items.map((item) => (
        <div className="item" key={item.slug}>
          <div className="item-info">
            <div className="item-image">
              <a href={`/${path}/${item.slug}`}>
                <Image path={item.image} width={width} height={width} type={path} />
              </a>
            </div>
            <div className="item-content">
              <div className="item-title">{item.name}</div>
              {categorized && (
                <div className="item-category">{item.category?.name}</div>
              )}
              <div className="item-rate">
                {item.user_rating ? item.user_rating : ''}&nbsp;
                {Boolean(item.user_rating) && <StarIcon />}
              </div>
            </div>
          </div>
          <div className="item-action">
            <a className="item-action-btn" href={`/${path}/${item.slug}`}>
              Details
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ItemsGrid;
